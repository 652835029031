import { useForm } from "react-hook-form"
import Icons from "../../components/common/icons"
import Header from "../../layout/header"
import { zodResolver } from "@hookform/resolvers/zod"
import { loginSchema, LoginSchema } from "./schema"
import { useState } from "react"
import { useResetPassword } from "../userManagement/login/api"
import { useLocation, useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"

const passwordEndAdorement =
    'appearance-none relative block w-full pl-6 py-4 border border-authFormBorder placeholder-authFormBorder text-authFormBorder focus:outline-none focus:ring-purple-500 focus:border-gray-300 focus:z-10 sm:text-sm md:text-[20px]'

const PasswordResetLink: React.FC = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const location = useLocation() // Access the current location
    const searchParams = new URLSearchParams(location.search) // Parse query params
    const resetPasswordToken = searchParams.get("reset_password_token") // Extract the token
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate();


    const handleOnSuccess = async () => {
        enqueueSnackbar("Password reset successfully!", { variant: "success" });
        navigate("/"); 
    };
    const { mutate } = useResetPassword(handleOnSuccess)

    const {
        handleSubmit,
        register,
        formState: { isValid }
    } = useForm<LoginSchema>({
        mode: 'onChange',
        resolver: zodResolver(loginSchema),
    })

    const handleData = async (data: LoginSchema) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      
        if (!data.password || !data.confirm_password) {
          enqueueSnackbar("Password and Confirm Password cannot be blank.", {
            variant: "error",
          });
          return;
        }
      
        if (!passwordRegex.test(data.password)) {
          enqueueSnackbar(
            "Password must be at least 6 characters long, include both letters, numbers, and at least one special character.",
            {
              variant: "error",
            }
          );
          return;
        }
      
        if (data.password !== data.confirm_password) {
          enqueueSnackbar("Passwords do not match. Please try again.", {
            variant: "error",
          });
          return;
        }
      
        const login = {
          password: data.password,
          reset_password_token: resetPasswordToken,
        };
        mutate(login);
      };
      



    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSubmit(handleData)
        }
    }

    return (
        <>
            <div className="h-screen">
                <Header auth={true} />
                <form
                    onSubmit={handleSubmit(handleData)}
                    onKeyDown={handleKeyDown}
                    className="h-5/6"
                >

                    <div className="min-h-full flex items-center justify-evenly 2xl:py-6 py-3 px-4 sm:px-6 md:px-14 2xl:px-8">
                        <div className="max-w-xl w-9/12 bg-bgWhite">
                            <div className="2xl:mb-16 mb-8">
                                <span className="text-start md:text-[20px] font-normal text-black">
                                    Enter your New Password and Confirm Password to Reset....
                                </span>
                            </div>
                            <div className="mt-4 mb-8">

                                <div className="my-8">
                                    <div className="relative flex items-center">
                                        <div className="w-full">
                                            <input
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                className={passwordEndAdorement}
                                                placeholder="New Password"
                                                {...register('password')}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword((prev) => !prev)}
                                            className="absolute right-4 text-gray-600 hover:text-black focus:outline-none z-[10]"
                                        >
                                            {showPassword ? (
                                                <Icons name="eye" />
                                            ) : (
                                                <Icons name="eye-close" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="my-8">
                                    <div className="relative flex items-center">
                                        <div className="w-full">
                                            <input
                                                id="confirm_password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                className={passwordEndAdorement}
                                                placeholder="Confirm New Password"
                                                {...register('confirm_password')}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => setShowConfirmPassword((prev) => !prev)}
                                            className="absolute right-4 text-gray-600 hover:text-black focus:outline-none z-[10]"
                                        >
                                            {showConfirmPassword ? (
                                                <Icons name="eye" />
                                            ) : (
                                                <Icons name="eye-close" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="my-8 block">
                                    <button
                                        type="submit"
                                        disabled={!isValid}
                                        className="px-6 py-4 w-full inline-block bg-authFormBorder font-medium text-xl text-bgWhite tracking-widest cursor-pointer hover:opacity-90  transition-opacity uppercase"
                                    >
                                        RESET NOW
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <img src="/auth-side-image.png" alt="Ayush side image" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default PasswordResetLink
