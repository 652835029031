import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useAppStore } from '../../../store/appStore'
import { useAuthStore } from '../../../store/authStore'
import { LoginSchema } from './schema'

import { useNavigate } from 'react-router-dom'
import { useClearFilter } from '../../../store/filterSore/clearStore'
import apiUrl from '../../../apis/api.url'
import { getData, postData, postFormData, updateFromData } from '../../../apis/api.helpers'
import { getErrorMessage } from '../../../utilities/parsers'

export const useLogin = (handleOnSuccess: any) => {
  const {
    setToken,
    setAuthenticated,
    // setPermissionData,
    setRoleData,
    setUserData,
    setRole,
    // userData,
  } = useAuthStore()

  const handleClear = useClearFilter()
  const { setIsLoading } = useAppStore()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  async function fetchUserData() {
    try {
      const res = await getData(apiUrl.PROFILE_URL)
      // console.log(res)
      setUserData({
        id: res.id,
        name: res.name,
        role: res.role,
        role_id: res.role_id,
        email: res.email,
        district: res.district,
        district_id: res.district_id,
        stream: res.stream,
        image: res?.image_url,
        primary_stock_threshold: res?.primary_stock_threshold,
        institution_name: res?.institution_name,
        institution_phone_number: res?.institution_phone_number,
        institution_land_number: res?.institution_land_number,
        district_name: res?.district_name,
      })
      setRoleData({
        name: res.name,
        id: res.id,
      })
      if (res?.role_id) navigate('/dashboard')
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(err.message, {
        variant: 'error',
      })
    }
  }

  const loginMutation = useMutation(
    async (params: LoginSchema) => {
      setIsLoading(true)

      const response = await postData(apiUrl.OTPLOGIN, params)
      // console.log(response)
      return response
    },
    {
      onError: (err: any) => {
        // console.log(err.message)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      },
      onSuccess: (data: any) => {
        const res = data
        setToken(res.bearer_token)
        setAuthenticated(true)
        setRole(res.role)
        handleOnSuccess?.()
        handleClear()
        fetchUserData()
        // setUserData({
        //   id: res.user_data?.id,
        //   name: res.user_data?.name,
        //   is_admin: res.user_data?.is_admin,
        //   is_operations_head: res.user_data?.is_operations_head,
        //   email: res.user_data?.user?.email,
        //   email_2: res.user_data?.user?.email_2,
        //   first_name: res.user_data?.user?.first_name,
        //   last_name: res.user_data?.user?.last_name,
        //   mobile: res.user_data?.user?.mobile,
        //   mobile_2: res.user_data?.user?.mobile_2,
        //   username: res.user_data?.user?.username,
        // })
        // setRoleData({
        //   name: res.user_data?.role?.name,
        //   id: res.user_data?.role?.id,
        // })
        // setPermissionData(res.user_data?.role?.permissions)

        // queryClient.invalidateQueries(['list']) refresh api call
      },

      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  return loginMutation
}

export const useEmailLogin = (handleOnSuccess: any) => {
  const {
    setToken,
    setAuthenticated,
    // setPermissionData,
    setRoleData,
    setUserData,
    setRole,
    // userData,
  } = useAuthStore()

  const handleClear = useClearFilter()
  const { setIsLoading } = useAppStore()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  async function fetchUserData() {
    try {
      const res = await getData(apiUrl.PROFILE_URL)
      // console.log(res)
      setUserData({
        id: res.id,
        name: res.name,
        role: res.role,
        role_id: res.role_id,
        email: res.email,
        district: res.district,
        district_id: res.district_id,
        stream: res.stream,
        image: res?.image_url,
        primary_stock_threshold: res?.primary_stock_threshold,
        institution_name: res?.institution_name,
        institution_phone_number: res?.institution_phone_number,
        institution_land_number: res?.institution_land_number,
        district_name: res?.district_name,
      })
      setRoleData({
        name: res.name,
        id: res.id,
      })
      if (res?.role_id) navigate('/dashboard')
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(err.message, {
        variant: 'error',
      })
    }
  }

  const loginMutation = useMutation(
    async (params: LoginSchema) => {
      setIsLoading(true)

      const response = await postData(apiUrl.LOGIN_URL, params)
      // console.log(response)
      return response
    },
    {
      onError: (err: any) => {
        // console.log(err.message)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      },
      onSuccess: (data: any) => {
        const res = data
        setToken(res.bearer_token)
        setAuthenticated(true)
        setRole(res.role)
        handleOnSuccess?.()
        handleClear()
        fetchUserData()
        // setUserData({
        //   id: res.user_data?.id,
        //   name: res.user_data?.name,
        //   is_admin: res.user_data?.is_admin,
        //   is_operations_head: res.user_data?.is_operations_head,
        //   email: res.user_data?.user?.email,
        //   email_2: res.user_data?.user?.email_2,
        //   first_name: res.user_data?.user?.first_name,
        //   last_name: res.user_data?.user?.last_name,
        //   mobile: res.user_data?.user?.mobile,
        //   mobile_2: res.user_data?.user?.mobile_2,
        //   username: res.user_data?.user?.username,
        // })
        // setRoleData({
        //   name: res.user_data?.role?.name,
        //   id: res.user_data?.role?.id,
        // })
        // setPermissionData(res.user_data?.role?.permissions)

        // queryClient.invalidateQueries(['list']) refresh api call
      },

      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  return loginMutation
}

export const generateOtp = (input: any) => {
  return postFormData(apiUrl.GENERATEOTP, input)
}

export const useGenerateOtp = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(generateOtp, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      // enqueueSnackbar('Institution Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const generatelink = (input: any) => {
  return updateFromData(apiUrl.RESET_PASSWORD_LINK, input)
}

export const useForgetPassword = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(generatelink, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Password Reset Link Has Been Sent To Your Registered Email', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const generateResetPassword = (input: any) => {
  return updateFromData(`${apiUrl.RESET_PASSWORD}?reset_password_token=${input.reset_password_token}`, input)
}

export const useResetPassword = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(generateResetPassword, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Password Reset Link Has Been Sent To Your Registered Email', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}