import { z } from 'zod'

export const loginSchema = z.object({

  password: z.string().optional().nullable(),
  confirm_password: z.string().optional().nullable(),

})

export type LoginSchema = z.infer<typeof loginSchema>
