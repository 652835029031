import { z } from 'zod'

import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'
const isAdult = (value: Date) => {
  const eighteenYearsAgo = new Date()
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
  return value <= eighteenYearsAgo
}
export const formSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  gender: z
    .string({ invalid_type_error: 'Gender is required.' })
    .min(1, { message: 'Gender is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  address: z
    .string({ invalid_type_error: 'Address is required.' })
    .min(1, { message: 'Address is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  stream: z
    .string({ invalid_type_error: 'Stream is required.' })
    .min(1, { message: 'Stream is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  availability: z
    .string({ invalid_type_error: 'Availability is required.' })
    .min(1, { message: 'Availability is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  dob: z.date({ required_error: 'Date of Birth is Required' }).refine(isAdult, {
    message: 'You must be at least 18 years old.',
  }),
  joining_date: z.date({ required_error: 'Joining Date is Required' }),
  // .min(1, { message: 'Date of birth is required.' })

  phone_number: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,10})?$/.test(value) &&
            value.length <= 10 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a valid Mobile number',
      }
    ),
  email: z
    .string({ required_error: 'Enter Email' })
    .min(5, 'Enter a valid Email')
    .superRefine((value: any, ctx: any) => {
      if (value && value !== '') {
        if (!/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  state: z.string().optional().nullable(),
})

export type CreateSchema = z.infer<typeof formSchema>

// assembly_constituency_id: z.string(),
// pan_no: z
//   .string()
//   .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
//     message: 'Invalid PAN number format',
//   })
//   .refine((value) => value === '' || noLeadingSpaces(value), {
//     message: 'Leading spaces are not allowed',
//   })
//   .optional(),

// stateId: z.string().optional().nullable(),
// code: z.string().optional().nullable(),
// city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
//   message: 'Leading spaces are not allowed',
// }),
// address_1: z.string().optional().nullable(),
// address_2: z.string().optional().nullable(),
// country: z.string().min(1, 'Country is required.'),
// countryId: z.string().optional().nullable(),

// pin_code: z.string().optional().nullable(),
// phone_number: z
// .string()
// .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
//   message: 'Invalid mobile number.',
// })
// .optional(),
